import { createApp } from 'vue';

import App from './App.vue';

import { router } from '$central/router';
import { bootstrapVue } from '$common/bootstrappers/vue';

import.meta.glob(['../../assets/**']);

const app = createApp(App);

bootstrapVue(app);

app.use(router);
app.mount('#app');
